.static-view-v1 {
	margin-top: var(--page-margin-top);

	&.eigentumer-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/hero_eigentuemer.webp);

		}
	}

	&.immobilien-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/hero_immobilien.webp);
		}
	}
}